@use 'sass:math';
@use 'sass:map';
@import '/src/assets/scss/shared';

.camp {
  @include display-flex(column) {
    position: relative;
    background-color: map.get($color-list, 'neutral', 200);
    border: 1px solid transparent;
    border-radius: 24px;
    padding: 24px 24px 16px;

    &--active {
      border-color: map.get($color-list, 'green', 500);
    }
  }

  &__remove {
    position: absolute;
    width: 2.4rem;
    height: 2.4rem;
    right: 1.2rem;
    top: 1.2rem;
  }

  &__block {
    &__1 {
      @include display-flex;
    }

    &__2 {
      @include display-flex(column) {
        margin-bottom: 2.4rem;
      }
    }

    &__3 {
      @include display-flex {
        margin-bottom: 2rem;
        align-items: stretch;
        justify-content: space-evenly;
        @include media-breakpoint-up('xxl') {
          @include display-flex;
        }
      }
    }

    &__4 {
      @include display-flex {
        flex-grow: 1;
      }
    }

    &__5 {
      @include display-flex {
        align-items: center;
        justify-content: space-between;
        margin-top: auto;
      }
    }
  }

  &__logo {
    @include display-flex {
      align-items: center;
      justify-content: center;
      width: 64px;
      height: 64px;
      min-width: 64px;
      min-height: 64px;
      background-color: map.get($color-list, 'neutral', 100);
      border-radius: 50%;
      margin-right: 24px;
      overflow: hidden;

      @include media-breakpoint-up('xxl') {
        margin-right: 30px;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    h6 {
      color: map.get($color-list, 'green', 600);
    }
  }

  &__label {
    margin-bottom: 4px;

    @include caption-text {
      color: map.get($color-list, 'neutral', 400);
    }
  }

  &__text {
    @include body-text(2) {
      color: map.get($color-list, 'neutral', 600);
    }
  }

  &__name {
    margin-bottom: 4px;
    @include subtitle-text(2);
    color: map.get($color-list, 'neutral', 800);
  }

  &__address {
    margin-bottom: 0.8rem;

    @include body-text(3) {
      color: map.get($color-list, 'neutral', 500);
    }
  }

  &__website {
    a {
      @include body-hyperlink-text(3) {
        color: map.get($color-list, 'green', 500);
      }
    }
  }

  &__roles {
    @include media-breakpoint-only('xs') {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 21.5rem;
    }

    &__wrapper {
      @include media-breakpoint-only('xs') {
        margin-bottom: 3.2rem;
      }

      @include media-breakpoint-up('xxl') {
        margin-right: 3.2rem;
      }
    }

    &__inner {
      @include media-breakpoint-only('xs') {
        @include display-flex {
          justify-content: space-between;
        }
      }

      @include media-breakpoint-up('xxl') {
        max-width: 190px;
      }

      span {
        @include body-hyperlink-text(2) {
          color: map.get($color-list, 'green', 500);
        }
      }
    }
  }

  &__ambassadors__wrapper {
    margin-right: 2rem;

    @include media-breakpoint-up('xxl') {
      margin-right: 3.6rem;
    }
  }

  &__button {
    &s {
      @include display-flex;
    }

    &__edit {
      width: 80px;
      height: 4rem !important;
    }

    &__use {
      width: 80px;
      margin-left: 8px;

      @include media-breakpoint-up('xxl') {
        margin-left: 16px;
      }
    }
  }
}
