@use 'sass:map';
@import '/src/assets/scss/shared';

.modal {
  width: 450px;
  padding: 48px 50px 30px 64px;

  * {
    transition-duration: unset;
  }

  &__content {
    @include display-flex(column) {
      height: 100%;
      align-items: center;
    }
  }
}

.close {
  position: absolute;
  background-color: transparent;
  padding: 0px;
  border: none;
  cursor: pointer;
  top: 16px;
  right: 16px;
}

.camp {
  &__logo {
    @include display-flex {
      position: relative;
      width: 80px;
      height: 80px;
      align-items: center;
      justify-content: center;
      background-color: map.get($color-list, 'neutral', 100);
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: 24px;
    }

    img {
      width: 100%;
      height: 100%;
    }

    span {
      font-family: 'Rubik-Medium';
      font-weight: 500;
      font-size: 30px;
      line-height: 40px;
      color: map.get($color-list, 'green', 600);
    }
  }
}

.text {
  &__title {
    text-align: center;
    font-family: 'Avenir-Bold';
    color: #808080;
    margin-bottom: 8px;
  }

  &__message {
    text-align: center;
    font-family: 'Avenir-light';
    color: #808080;
    margin-bottom: 32px;
  }
}

.button {
  &__register {
    padding: 1.2rem 4rem !important;
    margin-bottom: 20px;
  }
  &__promocode {
    border-color: map.get($color-list, 'neutral', 400);
    @include body-text(3);
    color: map.get($color-list, 'neutral', 800);
    width: 260px;
    margin-bottom: 20px;

    &:hover {
      background-color: map.get($color-list, 'neutral', 200);
    }

    &:active,
    &:focus {
      background-color: map.get($color-list, 'neutral', 300);
    }
  }
}

.footer {
  text-align: center;
  font-family: 'Avenir-Italic';
  color: #808080;
}
