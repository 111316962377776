@use "sass:math";
@use "sass:map";
@import "/src/assets/scss/shared";

.stat_item {
  @include display-flex;

  &__block {
    &__1 {
      @include display-flex {
        padding: 12px 8px 12px 16px;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        background-color: map.get($color-list, "neutral", 200);

        @include media-breakpoint-only("md") {
          align-items: center;
        }

        @include media-breakpoint-up("md") {
          width: 255px;
          padding: 16px 16px 18px 24px;
          margin-right: 24px;
          border-top-right-radius: 12px;
          border-bottom-right-radius: 12px;
        }

        @include media-breakpoint-up("xxl") {
          width: 330px;
        }
      }
    }

    &__2 {
      display: none;

      @include media-breakpoint-up("md") {
        @include display-flex(column);
      }

      @include media-breakpoint-up("xxl") {
        margin-bottom: 22px;
      }
    }

    &__3 {
      @include display-flex(column) {
        flex-grow: 1;
        justify-content: space-between;
        padding: 10px 10px 10px 0px;
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
        background-color: map.get($color-list, "neutral", 200);

        @include media-breakpoint-up("xxl") {
          padding: 24px 16px 24px 0px;
          border-top-left-radius: 12px;
          border-bottom-left-radius: 12px;
        }
      }
    }
  }

  &__logo {
    @include display-flex {
      align-items: center;
      justify-content: center;
      width: 64px;
      height: 64px;
      min-width: 64px;
      min-height: 64px;
      background-color: map.get($color-list, 'neutral', 100);
      border-radius: 50%;
      overflow: hidden;

      @include media-breakpoint-up("md") {
        width: 40px;
        height: 40px;
        min-width: 40px;
        min-height: 40px;
        margin-right: 16px;
      }

      @include media-breakpoint-up("xxl") {
        width: 64px;
        height: 64px;
        min-width: 64px;
        min-height: 64px;
        margin-right: 16px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    h6 {
      color: map.get($color-list, "green", 600);
    }
  }

  &__name {
    margin-bottom: 4px;
    @include subtitle-text(2);
    color: map.get($color-list, "neutral", 800);

    @include media-breakpoint-up("md") {
      display: block;
    }
  }

  &__address {
    margin-bottom: 8px;
    display: none;

    @include body-text(3) {
      color: map.get($color-list, "neutral", 500);
    }

    @include media-breakpoint-up("xxl") {
      display: block;
    }
  }

  &__website {
    display: none;

    a {
      @include body-hyperlink-text(3) {
        color: map.get($color-list, "green", 500);
      }
    }

    @include media-breakpoint-up("xxl") {
      display: block;
    }
  }

  &__progress {
    @include display-flex {
      align-items: center;
    }

    div {
      height: 8px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      margin-right: 8px;
    }

    &--green {
      background-color: map.get($color-list, "green", 400);

      & + span {
        color: map.get($color-list, "green", 500);
      }
    }

    &--orange {
      background-color: map.get($color-list, "orange", 400);

      & + span {
        color: map.get($color-list, "orange", 500);
      }
    }

    &--blue {
      background-color: map.get($color-list, "blue", 400);

      & + span {
        color: map.get($color-list, "blue", 500);
      }
    }

    &--red {
      background-color: map.get($color-list, "red", 400);

      & + span {
        color: map.get($color-list, "red", 500);
      }
    }

    span {
      @include subtitle-text(3);
    }
  }
}
